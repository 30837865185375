import { reactive, ref } from "vue";
import { list } from "@/api/coupon/couponVipApi";
import useMessage from "@/hooks/useMessage";
import moment from "moment";
import { dateTimeFormat } from "@/utils/utils";
const useCouponVIP = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const couponVo = reactive({
    couponPackageName: "",
    couponType: null,
    parkType: null,
    couponValue: "",
    publishNum: null,
    effectiveDay: null,
    useDate: null,
    useStartDate: "",
    useEndDate: "",
    pubDate: null,
    pubStartDate: "",
    pubEndDate: "",
    remark: "",
    createAccount: "",
  });

  const initCouponVo = record => {
    const {
      couponPackageName,
      couponType,
      parkType,
      couponValue,
      publishNum,
      effectiveDay,
      useStartDate,
      useEndDate,
      pubStartDate,
      pubEndDate,
      remark,
    } = record;
    couponVo.couponPackageName = couponPackageName;
    couponVo.couponType = couponType;
    couponVo.parkType = parkType;
    couponVo.couponValue = couponValue;
    couponVo.publishNum = publishNum;
    couponVo.effectiveDay = effectiveDay;
    couponVo.remark = remark;
    couponVo.useStartDate = useStartDate;
    couponVo.useEndDate = useEndDate;
    couponVo.pubStartDate = pubStartDate;
    couponVo.pubEndDate = pubEndDate;
    couponVo.pubDate =
      pubStartDate && pubEndDate
        ? [moment(pubStartDate), moment(pubEndDate)]
        : null;
    couponVo.useDate =
      useStartDate && useEndDate
        ? [moment(useStartDate), moment(useEndDate)]
        : null;
  };

  const couponTypeNameEnum = {
    1: "折扣券",
    2: "金额券",
  };

  const parkTypeNameEnum = {
    1: "道路停车泊位",
    2: "公共停车场",
  };

  const couponTypes = ref([
    { value: 1, label: "折扣券" },
    // { value: 2, label: "金额券" },
  ]);

  const parkTypes = ref([
    { value: 1, label: parkTypeNameEnum[1] },
    { value: 2, label: parkTypeNameEnum[2] },
  ]);

  const couponStates = [
    { value: 1, label: "未开始" },
    { value: 2, label: "领取中" },
    { value: 3, label: "已领完" },
    { value: 4, label: "已结束" },
  ];

  const couponStatesEnum = {
    NOT_STARTED: 1, // 未开始
    RECEIVING: 2, // 领取中
    COLLECTED: 3, // 已领完
    ENDED: 4, // 已结束
  };

  const columns = [
    {
      title: "序号",
      align: "center",
      width: 50,
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "券包编号",
      align: "center",
      width: 200,
      dataIndex: "couponPackageId",
    },
    {
      title: "名称",
      align: "center",
      width: 150,
      dataIndex: "couponPackageName",
    },
    {
      title: "券类型",
      align: "center",
      width: 80,
      dataIndex: "couponType",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return couponTypeNameEnum[record.couponType];
      },
    },
    {
      title: "车场类型",
      align: "center",
      width: 100,
      dataIndex: "couponType",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return parkTypeNameEnum[record.parkType];
      },
    },
    {
      title: "券面值",
      align: "center",
      width: 80,
      dataIndex: "couponValue",
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return `${record.couponValue}${record.couponType === 1 ? "折" : "元"}`;
      },
    },
    {
      title: "状态",
      align: "center",
      width: 80,
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return !record.state ? "未知" : couponStates[record.state - 1].label;
      },
    },
    {
      title: "券有效期",
      align: "center",
      dataIndex: "effectiveDay",
      width: 150,
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return record.effectiveDay
          ? `领取后${record.effectiveDay}天有效`
          : `${record.useStartDate}至${record.useEndDate}`;
      },
    },
    {
      title: "领取量",
      align: "center",
      width: 80,
      dataIndex: "getNum",
    },
    {
      title: "发放量",
      align: "center",
      width: 80,
      dataIndex: "publishNum",
    },
    {
      title: "发放时间",
      align: "center",
      width: 400,
      // eslint-disable-next-line no-unused-vars
      customRender: ({ _text, record }) => {
        return `${record.pubStartDate}至${record.pubEndDate}`;
      },
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list({
        couponPackageId: params.couponPackageId || "",
        couponPackageName: params.couponPackageName || "",
        couponType: params.couponType || "",
        couponState: params.couponState || "",
        page,
        pageSize,
      });
      dataList.list = res.data;
      total.value = res.count;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    couponPackageId: "",
    couponPackageName: "",
    couponType: null,
    couponState: null,
  });

  const changeUseDate = dateArr => {
    couponVo.useStartDate = moment(dateArr[0]).format(dateTimeFormat);
    couponVo.useEndDate = moment(dateArr[1]).format(dateTimeFormat);
  };
  const changePubDate = dateArr => {
    couponVo.pubStartDate = moment(dateArr[0]).format(dateTimeFormat);
    couponVo.pubEndDate = moment(dateArr[1]).format(dateTimeFormat);
  };
  /**
   * value[0] 校验的pubStartDate Memont格式
   * Value[1]校验的pubEndDate Memont格式
   */
  const validatePubDate = async (_rule, value) => {
    if (!value || !value[0] || !value[1]) {
      return Promise.reject("发放时段不能为空");
    } else if (!couponVo.useDate || !couponVo.useDate.length) {
      return Promise.reject("请选选择有效时段");
    } else if (value[0].unix() > couponVo.useDate[0].unix()) {
      return Promise.reject("发放开始时间应早于等于有效开始时间");
    } else if (value[1].unix() > couponVo.useDate[1].unix()) {
      return Promise.reject("发放结束时间应早于等于有效结束时段");
    } else {
      return Promise.resolve();
    }
  };
  const validatePubDate1 = async (_rule, value) => {
    if (!value || !value[0] || !value[1]) {
      return Promise.reject("发放时段不能为空");
    } else {
      return Promise.resolve();
    }
  };
  const validateCouponValue = async (_rule, value) => {
    if (!value) {
      return Promise.reject("额度不能为空");
    }
    const regExp = /^((0.[1-9]{1})|(([1-9]{1})(.\d{1})?))$/;
    if (!regExp.test(value)) {
      return Promise.reject("请输入正确折扣，例: 9折、9.5折");
    }
    return Promise.resolve();
  };
  const validateCouponValueByCash = async (_rule, value) => {
    if (!value) {
      return Promise.reject("额度不能为空");
    }
    const regExp = /^[1-9]\d*$/;
    if (!regExp.test(value)) {
      return Promise.reject("额度只能为正整数");
    }
    if (Number.parseInt(value) > 10000) {
      return Promise.reject("金额券额度不能大于1000元");
    }
    return Promise.resolve();
  };
  const disabledDate = current => {
    return current && current < moment().subtract(1, "days").endOf("day");
  };
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    couponVo,
    couponTypes,
    parkTypes,
    couponStates,
    changeUseDate,
    changePubDate,
    initCouponVo,
    couponStatesEnum,
    validatePubDate,
    validatePubDate1,
    validateCouponValue,
    validateCouponValueByCash,
    disabledDate,
  };
};
export default useCouponVIP;
